<template>
<div id="page" class="eq-distribution-page-index">
  <div class="edpi-top">
    <div v-if="pieHide" class="no-chart row-start">
      <div class="title">已投放设备总数：0</div>
      <div class="no-data column-center-center">暂无数据</div>
    </div>
    <ed-pie-chart v-else @pieData="(e) => {getDeviceType(e)}" @showNull="(e) => {pieShowNull(e)}" />
  </div>
  <div class="edpi-bottom row-start-start">
    <el-button type="primary" v-if="btnShow" @click="goBack()">返回</el-button>
    <ed-bar-chart v-if="pieData!=null&&noBar==false" ref="barChartRef" @showNull="(e) => {noBar=e.type; currentLevel=e.level}" :pieData="pieData" @showBtn="(e) => {btnShow = e}" />
    <div v-else-if="noBar" class="no-chart row-start">
      <div class="title">按设备类型统计</div>
      <div class="no-data column-center-center">暂无数据</div>
    </div>
  </div>
</div>
</template>

<script>
// 统计分析 - 设备分布
import { nextTick, reactive, ref, toRefs } from 'vue';
import edPieChart from './comps/edPieChart.vue';
import EdBarChart from './comps/edBarChart.vue';
export default {
  components: { edPieChart, EdBarChart },
  setup() {
    const barChartRef = ref(null);
    const state = reactive({
      pieData: null,
      btnShow: false,
      noBar: false,
      currentLevel: 1,
      pieHide: false
    })

    // 饼图是否展示
    const pieShowNull = (e) => {
      state.pieHide = e;
      if (e) {
        state.noBar = true;
      }
    };
    // 饼图传递过来的设备类型 赋值
    const getDeviceType = (e) => {
      state.pieData = e;
      state.noBar = false;
      nextTick(() => {
        barChartRef.value.getData(state.pieData);
      })
      if (e.type == "click") {
        setTimeout(() => {
          barChartRef.value.level = 1;
          barChartRef.value.getData(state.pieData);
        }, 100)
      }
    };

    // 返回上一级图表
    const goBack = () => {
      // let key = "level" + (barChartRef.value.level - 1);
      let key = "level",
        tempLevel = 0;
      if (state.noBar) {
        key += state.currentLevel - 1;
        tempLevel = state.currentLevel - 1;
      } else {
        key += barChartRef.value.level - 1;
        tempLevel = barChartRef.value.level - 1;
      }
      let chartData = JSON.parse(localStorage.getItem(key));
      state.noBar = false;
      state.btnShow = tempLevel == 1 ? false : true;
      setTimeout(() => {
        barChartRef.value.level = tempLevel;
        barChartRef.value.chartData = chartData;
        barChartRef.value.formatterData();
      }, 200)
    }

    return {
      ...toRefs(state),
      getDeviceType,
      barChartRef,
      goBack,
      pieShowNull
    }
  }
}
</script>

<style lang="scss" scoped>
.eq-distribution-page-index {
  width: 100%;

  .edpi-top {
    width: 100%;
    height: 50%;
    border-bottom: 2px solid #e2dfdf;

    .no-chart {
      width: 100%;
      height: 100%;

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      .no-data {
        width: 100%;
        height: calc(100% - 25px);
      }
    }
  }

  .edpi-bottom {
    width: 100%;
    height: 50%;
    margin-top: 14px;

    .el-button {
      margin-right: 6px;
    }

    .no-chart {
      width: 100%;
      height: 100%;

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      .no-data {
        width: 100%;
        height: calc(100% - 25px);
      }
    }
  }
}
</style>
