<template>
<div class="ed-distribution-bar-chart" v-loading="barLoading" :id="chartId" />
</template>

<script>
// 统计分析 - 设备分布
import { useStore } from 'vuex';
import { getEqDisByDevicetypeAndArea, getEqDisByDevicetypeAndPagent } from "api/apis.js";
import { computed, onMounted, reactive, toRefs, inject } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
export default {
  props: {
    pieData: { type: Object, default: function () { return {} } },
  },
  setup(props, ctx) {
    const echart = inject("echart");
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      barLoading: false,
      chartId: computed(() => store.getters.UUID),
      chartData: null,
      level: 1,
      titleText: null,
      // area_names: computed(() => store.getters.areas_name)
      area_names: computed(() => {
        if (process.env.NODE_ENV == "development") {
          return null;
        } else {
          let path = route.path,
            menuAuth = JSON.parse(localStorage.getItem("menuAuth")),
            index = menuAuth.findIndex(t => t.path == path);
          if (menuAuth[index].txt_area_codes == "0") {
            return null;
          } else {
            return menuAuth[index].txt_area_codes;
          }
        }
      }),
    });

    onMounted(() => {
      // getData(null);
      window.onresize = () => {
        formatterData();
      }
    })

    // 获取数据
    const getData = (data) => {
      state.barLoading = true;
      state.titleText = data == null ? (props.pieData.deviceType.txt_type_name || props.pieData.deviceType.name) : (data.deviceType.txt_type_name || data.deviceType.name);
      let device_type_id = data == null ? props.pieData.deviceType.t_device_type_id : data.deviceType.t_device_type_id;
      let pagent_id = data == null ? 0 : data.pagent_id;
      if (state.level == 1) {
        let request = {
          device_type_id: device_type_id,
          area_names: state.area_names,
          agent_level: 1
        }
        if (request.area_names == null) delete request.area_names;
        getEqDisByDevicetypeAndArea(request).then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              formatterData();
            } else {
              state.chartData = null;
              ctx.emit("showNull", { type: true, level: state.level });
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.barLoading = false;
        })
      } else {
        getEqDisByDevicetypeAndPagent({
          device_type_id: device_type_id,
          pagent_id: pagent_id
        }).then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              formatterData();
            } else {
              state.chartData = null;
              ctx.emit("showNull", { type: true, level: state.level });
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.barLoading = false;
        })
      }
    };

    // 格式化数据
    const formatterData = () => {
      // 横坐标
      let xAxis = Array.from(new Set(state.chartData.map(t => t.txt_area_name))),
        // 数组 结果集
        seriesData = state.chartData.map(t => { return { name: t.txt_agent_name, value: [], t_agent_id: t.t_agent_id } });
      for (let i = 0; i < xAxis.length; i++) {
        for (let j = 0; j < seriesData.length; j++) {
          let tempIndex = state.chartData.findIndex(t => t.txt_area_name == xAxis[i] && t.txt_agent_name == seriesData[j].name);
          if (tempIndex != -1) {
            seriesData[j].value.push(state.chartData[tempIndex].cnt_devices);
          } else {
            seriesData[j].value.push(null);
          }
        }
      }
      initChart(xAxis, seriesData);
    };

    // 渲染图表
    const initChart = (xAxis, seriesData) => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let barChart = echart.init(document.getElementById(state.chartId)),
        level = null;
      if (state.level == 1) level = "省级合伙人";
      else if (state.level == 2) level = "市级合伙人";
      else if (state.level == 3) level = "市场专员";
      else level = "诊所";
      state.titleText = state.titleText.split(" / ")[0];
      state.titleText = state.titleText + " / " + level;
      barChart.setOption({
        xAxis: [{
          type: 'category',
          data: xAxis,
          name: "省/市"
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = `<div class='chart-tooltip'>${params[0].name}`;
            for (let i = 0; i < params.length; i++) {
              if (params[i].value) {
                result += `<div class='ct-line row-center-start'><div class='ct-point' style='background-color: ${params[i].color}'></div><div class='row-center-between'><div>${params[i].seriesName}：</div><div>${params[i].value}</div></div></div>`;
              }
            }
            result += "</div>";
            return result;
          }
        },
        title: { text: state.titleText },
        legend: { data: seriesData.map(t => t.name), left: "30%", width: "60%", type: "scroll", orient: 'horizontal' },
        yAxis: [{ type: 'value', name: "数量", axisLine: { show: true } }],
        series: seriesData.map(t => {
          return {
            name: t.name,
            data: t.value,
            type: 'bar',
            // barMinWidth: "8%"
          }
        })
      });
      if (state.level <= 3) {
        barChart.on("click", function (params) {
          localStorage.setItem("level" + state.level, JSON.stringify(state.chartData));
          state.level += 1;
          // 双重判断，防止多省有同一合伙人
          let index = state.chartData.findIndex(t => t.txt_agent_name == params.seriesName && t.txt_area_name == params.name);
          getData({ deviceType: props.pieData.deviceType, pagent_id: state.chartData[index].t_agent_id });

          if (state.level != 1) {
            ctx.emit("showBtn", true);
          }
        })
        console.log(state.level)
      }
      if (state.level == 1) {
        ctx.emit("showBtn", false);
      } else {
        ctx.emit("showBtn", true);
      }
    };

    return {
      ...toRefs(state),
      getData,
      formatterData
    }
  }
}
</script>

<style lang="scss" scoped>
.ed-distribution-bar-chart {
  height: 100%;
  // width: calc(100% - 60px);
  width: 100%;

  /deep/ {
    .chart-tooltip {
      .ct-line {
        margin-top: 6px;
      }

      .ct-point {
        width: 8px;
        height: 8px;
        border-radius: 6px;
        margin-right: 4px;
      }
    }
  }
}
</style>
