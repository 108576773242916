<template>
<div class="eq-distribution-pie-chart" v-loading="pieLoading" :id="chartId" />
</template>

<script>
// 统计分析 - 设备分布 - 饼图
import { useStore } from 'vuex'
import { computed, inject, onMounted, reactive, toRefs } from 'vue';
import { getEqDisData } from "api/apis.js";
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
export default {
  setup(props, ctx) {
    const store = useStore();
    const echart = inject("echart");
    const route = useRoute();
    const state = reactive({
      chartId: computed(() => store.getters.UUID),
      chartData: null,
      pieLoading: false,
      deviceType: null,
      // area_names: computed(() => store.getters.areas_name)
      area_names: computed(() => {
        if (process.env.NODE_ENV == "development") {
          return null;
        } else {
          let path = route.path,
            menuAuth = JSON.parse(localStorage.getItem("menuAuth")),
            index = menuAuth.findIndex(t => t.path == path);
          if (menuAuth[index].txt_area_codes == "0") {
            return null;
          } else {
            return menuAuth[index].txt_area_codes;
          }
        }
      }),
    });

    onMounted(() => {
      getData();
      window.onresize = () => {
        initChart();
      }
    });

    // 获取数据
    const getData = () => {
      state.pieLoading = true;
      if (state.area_names == null) {
        getEqDisData().then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              ctx.emit("pieData", { deviceType: state.chartData[0], pagent_id: 0, type: "default" });
              initChart();
            } else {
              state.chartData = null;
              ctx.emit("showNull", true);
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.pieLoading = false;
        })
      } else {
        getEqDisData({ area_names: state.area_names }).then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              ctx.emit("pieData", { deviceType: state.chartData[0], pagent_id: 0, type: "default" });
              initChart();
            } else {
              state.chartData = null;
              ctx.emit("showNull", true);
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.pieLoading = false;
        })
      }
    };

    // 初始化图表
    const initChart = () => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let pieChart = echart.init(document.getElementById(state.chartId));
      let total = state.chartData.reduce((a, b) => a + b.cnt_devices, 0);
      pieChart.setOption({
        title: { text: '已投放设备总数：' + total },
        tooltip: { trigger: 'item' },
        legend: { orient: 'vertical', left: '8%', top: "middle" },
        series: [{
          name: '投放数量',
          type: 'pie',
          radius: '50%',
          data: state.chartData.map(t => {
            return { value: t.cnt_devices, name: t.txt_type_name, t_device_type_id: t.t_device_type_id }
          }),
          label: {
            formatter: "{b}：{c}"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      });
      pieChart.on("click", function (params) {
        ctx.emit("pieData", { deviceType: params.data, pagent_id: 0, type: "click" });
      })
    };

    return {
      ...toRefs(state),
      initChart,
      getData
    }
  }
}
</script>

<style lang="scss" scoped>
.eq-distribution-pie-chart {
  height: 100%;
  width: 100%;
}
</style>
